<template>
    <!--begin::Root-->
		<div class="d-flex flex-column flex-root" id="kt_app_root">
			<!--begin::Authentication - Sign-up -->
			<div class="d-flex flex-column flex-lg-row flex-column-fluid">
				<!--begin::Body-->
				<div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
					<!--begin::Form-->
					<div class="d-flex flex-center flex-column flex-lg-row-fluid">
						<!--begin::Wrapper-->
						<div class="w-lg-500px p-10">
							<!--begin::Form-->
							<form class="form w-100" novalidate="novalidate" id="kt_sign_up_form" data-kt-redirect-url="/" action="" @submit.prevent="onSubmit">
								<!--begin::Heading-->
								<div class="text-center mb-11">
									<!--begin::Title-->
									<h1 class="text-gray-900 fw-bolder mb-3">Đăng ký</h1>
									<!--end::Title-->
									<!--begin::Subtitle-->
									<div class="text-gray-500 fw-semibold fs-6">Hoàn toàn miễn phí và rất đơn giản</div>
									<!--end::Subtitle=-->
								</div>
								<!--begin::Heading-->
								<!--begin::Input group=-->
                                <div class="fv-row mb-8">
									<input v-model="user.name" type="text" placeholder="Họ tên" name="name" autocomplete="off" class="form-control bg-transparent" />
									<!--end::Email-->
									<div v-if="this.errors.name != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
										<div data-field="name" data-validator="notEmpty">{{ this.errors.name }}</div>
									</div>
								</div>
								<div class="fv-row mb-8">
									<input v-model="user.userName" type="text" placeholder="Tên đăng nhập" name="userName" autocomplete="off" class="form-control bg-transparent" />
									<!--end::Email-->
									<div v-if="this.errors.userName != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
										<div data-field="userName" data-validator="notEmpty">{{ this.errors.userName }}</div>
									</div>
								</div>
								<!--begin::Input group-->
								<div class="fv-row mb-8" data-kt-password-meter="true">
									<!--begin::Wrapper-->
									<div class="mb-1">
										<!--begin::Input wrapper-->
										<div class="position-relative mb-3">
											<input v-model="user.password" class="form-control bg-transparent" type="password" placeholder="Mật khẩu" name="password" autocomplete="off" />
											<span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
												<i class="ki-duotone ki-eye-slash fs-2"></i>
												<i class="ki-duotone ki-eye fs-2 d-none"></i>
											</span>
										</div>
										<!--end::Input wrapper-->
										<!--begin::Meter-->
										<div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
										</div>
										<!--end::Meter-->
									</div>
									<!--end::Wrapper-->
									<!--begin::Hint-->
									<div class="text-muted">Sử dụng ít nhất 8 ký tự gồm ít nhất một chữ và số</div>
									<!--end::Hint-->
                                    <div v-if="this.errors.password != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
										<div data-field="password" data-validator="notEmpty">{{ this.errors.password }}</div>
									</div>
								</div>
								<!--end::Input group=-->
								<!--end::Input group=-->
								<div class="fv-row mb-8">
									<!--begin::Repeat Password-->
									<input v-model="user.confirmPassword" placeholder="Nhập lại mật khẩu" name="confirmPassword" type="password" autocomplete="off" class="form-control bg-transparent" />
									<!--end::Repeat Password-->
                                    <div v-if="this.errors.confirmPassword != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
										<div data-field="confirmPassword" data-validator="notEmpty">{{ this.errors.confirmPassword }}</div>
									</div>
								</div>
								<!--end::Input group=-->
								
								<!--begin::Submit button-->
								<div class="d-grid mb-10">
									<button type="submit" id="kt_sign_up_submit" class="btn btn-primary">
										<!--begin::Indicator label-->
										<span class="indicator-label">Đăng ký</span>
										<!--end::Indicator label-->
										<!--begin::Indicator progress-->
										<span class="indicator-progress">Please wait... 
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										<!--end::Indicator progress-->
									</button>
								</div>
								<!--end::Submit button-->
								<!--begin::Sign up-->
								<div class="text-gray-500 text-center fw-semibold fs-6">Bạn đã có tài khoản ? 
								<router-link to="/SignIn" class="link-primary fw-semibold">Đăng nhập</router-link></div>
								<!--end::Sign up-->
							</form>
							<!--end::Form-->
						</div>
						<!--end::Wrapper-->
					</div>
					<!--end::Form-->
					
				</div>
				<!--end::Body-->
				<!--begin::Aside-->
				<div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center background-authen order-1 order-lg-2" style="">
					<!--begin::Content-->
					<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100" style="min-height: 100vh;">
						<!--begin::Logo-->
						<a href="index.html" class="mb-0 mb-lg-12">
							<img alt="Logo" src="./../../../../public/frontend/media/logos/logominbg.png" class="h-60px h-lg-75px" />
						</a>
						<!--end::Logo-->
						
						<div style="height: 150px;"></div>
						
					</div>
					<!--end::Content-->
				</div>
				<!--end::Aside-->
			</div>
			<!--end::Authentication - Sign-up-->
		</div>
		<!--end::Root-->
</template>
<style scope>
.bgi-position-center{
	background-image: url(./../../../../public/frontend/media/misc/auth-bg.png)
}
</style>
<script>
import Swal from 'sweetalert2';
import router from '@/router/router.js';
export default{
	data() {
		return {
			user:{
                name:"",
				userName: "",
				password: "",
				confirmPassword: "",
                },
			errors:{
                name:"",
				userName: "",
				password: "",
				confirmPassword: "",
			},	
			isValidate:false,
		}
	},
	methods: {
		validate()
		{
			this.isValidate = true;
			this.errors={
                name:"",
				userName: "",
				password: "",
				confirmPassword: "",
			};
			if(!this.user.userName){
				this.errors.userName = "Vui lòng điền tên đăng nhập"
				this.isValidate = false;
			}else if(!this.validUserName(this.user.userName)){
				this.errors.userName = "Tên đăng nhập phải có ít nhất 6 đến 15 ký tự"
				this.isValidate = false;
			}
			if(!this.user.password){
				this.errors.password = "Vui lòng điền mật khẩu"
				this.isValidate = false;
			}else if(!this.validPassword(this.user.password)){
				this.errors.password = "Mật khẩu phải ít nhất 8 ký tự và có ít nhất 1 chữ và 1 số, không gồm ký tự đặc biệt!"
				this.isValidate = false;
			}

            if(!this.user.confirmPassword){
				this.errors.confirmPassword = "Vui lòng nhập lại mật khẩu"
				this.isValidate = false;
			}else if(this.user.confirmPassword != this.user.password){
				this.errors.confirmPassword = "Nhập lại mật khẩu không đúng"
				this.isValidate = false;
			}
			return this.isValidate;
		},
		validUserName(userName){
			var re = /^[A-Za-z0-9_-]{6,15}$/;
			return re.test(userName);
		},	
		validPassword(password){
			var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
			return re.test(password);
		},	
		onSubmit(){
			if(this.validate()){
				this.$apiService.signUp(this.user)
				.then((res)=>{
                   
					
					Swal.fire({
						text: "Đăng ký thành công!",
						icon: "success",
						buttonsStyling: false,
						confirmButtonText: "Ok",
						heightAuto: false,
						customClass: {
						confirmButton: "btn fw-semobold btn-light-primary",
						},
					}).then(() => {
						
						this.$apiService.addDisplayPropertiesByUserId(res.data).then(()=>{
							router.push({ name: 'SignIn' });
					})
					});
					
					
				}).catch((res)=>{
					Swal.fire({
						text: res.response.data.message, // Hiển thị thông điệp lỗi từ server
						icon: "error",
						buttonsStyling: false,
						confirmButtonText: "Try again!",
						heightAuto: false,
						customClass: {
						confirmButton: "btn fw-semobold btn-light-danger",
						},
					}).then(() => {
						
					});
				})
			}
			// Xử lý lỗi phản hồi từ server
		},
	},
}
</script>